// FormatMessages.js
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeSanitize from 'rehype-sanitize';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'; // Optional: For code syntax highlighting
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism'; // Optional: Syntax highlighting theme

const FormatMessages = ({ text }) => {
  return (
    <ReactMarkdown
      children={text}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeSanitize]}
      components={{
        // Optional: Customize how specific Markdown elements are rendered
        a: ({ node, ...props }) => (
          <a {...props} style={{ color: '#1e90ff' }} target="_blank" rel="noopener noreferrer" />
        ),
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter
              style={oneDark}
              language={match[1]}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
    
        },
        // Add more custom renderers if needed
      }}
      className='whitespace-pre-line'
    />
  );
};

export default FormatMessages;